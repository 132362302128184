import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { StyledDownload } from "./style";
import {downloadPassportDocPdf,getDownloadHistory,} from "../../features/visitor/visitorSlice";
import { decryptVal } from "../../utils/utility";

const DownloadHistory = () => {
    const dispatch = useDispatch();
    const { orderId } = useParams();
    const downloadFilePath = useSelector((state) => state?.visitor?.downloadFilePath);
    const downloadHistoryList = useSelector((state) => state?.visitor?.downloadHistoryList);
    console.log("downloadFilePath ",downloadFilePath);
    useEffect(() => {
        let data = { orderId: decryptVal(orderId) };
        dispatch(getDownloadHistory(data));
        document.title = "Ind-ECD";
    }, []);

    const handleDownloadList = (item) => {
        dispatch(downloadPassportDocPdf({
            orderId: decryptVal(orderId),
            customername: item,
            file1: downloadFilePath?.file1,
        })
        ).unwrap().then((res) => {
            if (res.status === 1) {
                // let data = { orderId: (orderId) };
                let data = { orderId: decryptVal(orderId) };
                dispatch(getDownloadHistory(data));
            }
        });
    };
    return (
        <StyledDownload>
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <p><b>Your application for an Electronic Customs Declaration has been completed successfully.</b></p>
                    </div>
                    <div className="col-md-12">
                    <p>
                    <b>
                    Thank you for using our services.
                    </b>
                    </p>
                    <form className="form-inline">
                    <p>
                    You can download and save your Electronic Customs Declaration by clicking the button below.
                    <br />
                    </p>
                    <div className="wrapper" style={{ textAlign: "center" }}>
                        <a
                            href={`${process.env.REACT_APP_BASE_URL}/${process.env.REACT_APP_PUBLIC_UPLOAD_PATH}docs/${decryptVal(orderId)}_${downloadFilePath?.file1}`}
                            download
                            style={{ display: "block" }}
                        >
                            <button
                            type="button"
                            name="doc1"
                            className="btn btn-primary mb-2"
                            id="download_btn"
                            onClick={() => handleDownloadList('customer')}
                            >
                            Download Now{" "}
                            <i className="fa fa-download" aria-hidden="true"></i>
                            </button>
                        </a>
                        <br />
                        <br />
                    </div>
                    </form>
                    </div>
                    <div className="col-md-12 Responsive-table"><br />
                        <h3>Downloaded History</h3>
                        <table className="table table-striped table-bordered myTable showhistory">
                        <thead>
                            <tr>
                            <th>#</th>
                            <th>Order ID</th>
                            <th>Date &amp; Time (EST)</th>
                            <th>Device IP Address</th>
                            <th>Device</th>
                            <th>Device Type</th>
                            <th>Browser</th>
                            <th>OS</th>
                            <th>Device Location</th>
                            </tr>
                        </thead>
                        {downloadHistoryList?.length > 0 && downloadHistoryList?.map((item, index) => {
                            return (
                                <tbody key={item?.id}>
                                <tr>
                                    <td>{index + 1}</td>
                                    <td>{item?.order_id}</td>
                                    <td>{item?.create_ts}</td>
                                    <td>{ item?.ip.replace(/^::ffff:/, '') }</td>
                                    <td>{item?.browser.split("-")?.[2]}</td>
                                    <td>{`${item?.browser.split("-")?.[2]} ${item?.browser.split("-")?.[3].replace("Unknown", "")}`}</td>
                                    <td>{item?.browser.split("-")?.[0]}</td>
                                    <td>{item?.os.replace("Unknown", "")}</td>
                                    <td>{item?.timezone}</td>
                                </tr>
                                </tbody>
                            );
                            })}
                        </table>
                    </div>
                </div>
            </div>
        </StyledDownload>
    );
};

export default DownloadHistory;
