import React from "react";
import { Link } from "react-router-dom";
import { EmailContainer, StyleLogo } from "./style";
import { useSelector } from "react-redux";
import { encryptVal } from "../../../utils/utility";
import AdminLogo from "../../../assets/images/logo.png";

const EmailContent = () => {
  const OrderDetails = useSelector((state) => state?.order?.orderDetails);

  return (
    <EmailContainer>
      <StyleLogo>
        <img
          src={AdminLogo}
          alt="AdminLogo"
          style={{ padding: "-37px", width: "130px" }}
        />
      </StyleLogo>
      <hr />
      <p style={{ fontSize: "16px" }}>
        <b>{`Dear Customer: ${OrderDetails?.full_name || ''} `}</b>
      </p>
      <p style={{ fontSize: "16px" }}>
        We would like to inform you that your ECD application has been
        successfully approved.
      </p>
      <p style={{ fontSize: "16px" }}>
        <b>An ECD is automatically linked to your passport.</b>
      </p>
      <p style={{ fontSize: "16px" }}>To download your ECD confirmation:</p>
      <p style={{ fontSize: "16px" }}>Please click on the link below</p>
      <p style={{ fontSize: "16px" }}>
        Username:{" "}
        <a href={`mailto:${OrderDetails?.email || ''}`}>
          {OrderDetails?.email || ''}
        </a>
      </p>
      <p style={{ fontSize: "16px" }}>Order ID: {OrderDetails?.order_id}</p>
      <br />
      <Link target="__blank" to={`https://admin-ind.online/track-order/${encryptVal(OrderDetails?.order_id)}`} className="apply-btn">
        Click Here
      </Link>
      <br />
      <br />
      <p style={{ fontSize: "16px" }}>
        <b>ECD Validity:</b>
      </p>
      <p style={{ fontSize: "16px" }}>
        The ECD is valid until the Expiration date noted in the document.
      </p>
      <p style={{ fontSize: "16px" }}>
        It is recommended, but not required, that you take a printed or digital
        copy of your ECD with you.
      </p>
      <p style={{ fontSize: "16px" }}>
        Please make sure you check the official requirements and health
        regulations before traveling to your destination as travel restrictions
        may be applied in some cases.
      </p>
      <p style={{ fontSize: "16px" }}>
        Please contact us with any questions you may have regarding your ECD.
      </p>
      <p style={{ fontSize: "16px" }}>
        Should you have any inquiries, please contact us through our{" "}
        <Link to="https://indonesia-ecd.services/contact.php">contact form</Link> or
        via email at{" "}
        <a href="mailto:inquiries@indonesia-ecd.services">
          inquiries@indonesia-ecd.services
        </a>{" "}
        indicating your order ID.
      </p>
      <p style={{ fontSize: "16px" }}>
        <b>Customer Service Number:</b> +1 (202) 905-0634
      </p>
      <p style={{ fontSize: "16px" }}>Order ID: {OrderDetails?.order_id}</p>
      <br />
      <p style={{ fontSize: "16px" }}>
        <b>Important Notice:</b>
      </p>
      <p style={{ fontSize: "16px" }}>
        If you are not satisfied with the service you have received or wish to
        file a complaint, please contact us at{" "}
        <a href="mailto:inquiries@indonesia-ecd.services">
          inquiries@indonesia-ecd.services
        </a>
        , where we will be happy to assist you or alternatively click on one of
        the links below for more information.
      </p>
      <p style={{ fontSize: "16px" }}>Thank you for using our services.</p>
      <p style={{ fontSize: "16px" }}>Regards,</p>
      <p style={{ fontSize: "16px" }}>
        <b>Processing Department</b>
      </p>
      <p style={{ fontSize: "16px" }}>
        <Link to="https://indonesia-ecd.services/terms.php">
          Terms
        </Link>{" "}
        |{" "}
        <Link to="https://indonesia-ecd.services/privacy.php">Privacy</Link> |{" "}
        <Link to="https://indonesia-ecd.services/privacy.php">Contact</Link> |{" "}
        <Link to="https://indonesia-ecd.services/refund.php">Refund</Link>
      </p>
    </EmailContainer>
  );
};

export default EmailContent;
