import React, {useState, useEffect} from "react";
import DataTable from "react-data-table-component";
import { DebounceInput } from "react-debounce-input";
import { StyledContainer, StyledOrderBlock, StyledPageTitle, SearchField } from "./style";
import { toast } from "react-toastify";
import { getCountryOrderDetails } from "../../../features/order/orderSlice";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

const CountryOrderList = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const [countryOrderData, setCountryOrderData] = useState([]);
    const isLoading = useSelector((state) => state?.order?.loading);
    const [searchText, setSearchText] = useState("");
    const [dateField, setDateField] = useState({
        fromDate: "",
        toDate: ""
    });
    const countryOrderDetails = useSelector(state => state?.order?.countryOrderDetails);
    

    useEffect(() => {
        setCountryOrderData(countryOrderDetails);
    }, [countryOrderDetails]);

    useEffect(() => {
        dispatch(getCountryOrderDetails({})).unwrap().then((res) => {
            if (res.status === 1) {
                toast.success(`${res?.message}`, {
                className: "toast-message",
                });
            } else {
                toast.error(`${res?.message}`, {
                className: "toast-message",
                });
            }
        });
        document.title = "Ind-ECD";
    }, [page, limit]);

    const handleSearch = (e) => {
        setSearchText(e.target.value);
        const filterText = e.target.value;
        if (
            countryOrderDetails &&
            countryOrderDetails.length > 0 &&
            countryOrderData
        ) {
            if (filterText !== "") {
                console.log("filterText ",filterText);
                console.log(typeof filterText);
                const filteredResult = countryOrderDetails
                    ?.filter((item) =>
                        (item?.nationality)
                            .toLowerCase()
                            .includes(filterText.toLocaleLowerCase())
                    )
                    .map((item) => {
                        return {
                            nationality: item?.nationality,
                            count: item?.count
                        };
                    });
                setCountryOrderData(filteredResult);
            } else {
                const stateData = countryOrderDetails?.map((item) => {
                    return {
                        nationality: item?.nationality ? item?.nationality : '-',
                        count: item?.count ? item?.count : '-',
                    };
                });
                setCountryOrderData(stateData);
            }
        }
    };
    
    
    const handleSubmit = () => {
        const data = { fromDate: dateField.fromDate, toDate: dateField.toDate }
        dispatch(getCountryOrderDetails(data)).unwrap().then((res) => {
            if (res.status === 401) {
                toast.error(`${res?.message}`, { className: "toast-message" });
                localStorage.removeItem("user");
                navigate("/");
            }
        });
    }

    const columns = [
        {
            name: "S. No",
            selector: (row, index) => (index+1),
            sortable: true,
        },
        {
            name: "State/Territory",
            selector: (row) => row.nationality,
            sortable: true,
        }, 
        {
            name: "Order Count",
            selector: (row) => row.count,
            sortable: true,
        }, 
        {
            name: "Standard Processing",
            selector: (row) => row.standard_count,
            sortable: true,
        },
        {
            name: "Priority Processing",
            selector: (row) => row.priority_count,
            sortable: true,
        },
        {
            name: "Future Order Processing",
            selector: (row) => row.future_count,
            sortable: true,
        },
    ];

    return (
        <StyledContainer>
        <StyledPageTitle>
          <h1>Country Order Details</h1>
        </StyledPageTitle>
        <form>
            <div className="d-flex">
                <div className="form-group">
                    <label>
                        From:
                        <input
                            type="date"
                            name="fromDate"
                            id="fromDate"
                            className="form-control input-class"
                            value={dateField.fromDate}
                            onChange={(e) => setDateField({ ...dateField, fromDate: e.target.value })}
                        />
                    </label>
                </div>
                <div className="form-group">
                    <label>
                        To:
                        <input
                            type="date"
                            name="toDate"
                            id="toDate"
                            className="form-control input-class"
                            value={dateField.toDate}
                            onChange={(e) => setDateField({ ...dateField, toDate: e.target.value })}
                        />
                    </label>
                </div>
                <div className="form-group">
                    <button
                        type="button"
                        className="blue-btn"
                        id="download_btn"
                        onClick={(e) => {
                            handleSubmit(e);
                        }}
                    >
                        Submit
                    </button>
                </div>
            </div>
          <StyledOrderBlock>
            <SearchField>
                <DebounceInput
                    minLength={0}
                    debounceTimeout={500}
                    type="text"
                    id="search_field"
                    name="search_field"
                    className="search-bar form-control"
                    value={searchText}
                    onChange={(e) => handleSearch(e)}
                    placeholder="Search"
                />
            </SearchField>
            <div className="table-block table-responsive">
                <DataTable
                    className="uspassport-custom-tbl"
                    columns={columns}
                    progressPending={isLoading}
                    data={countryOrderData}
                    fixedHeader={true}
                    fixedHeaderScrollHeight="550px"
                    pagination={countryOrderData?.length ? true : false}
                    noDataComponent="No records found"
                    setPage={setPage}
                    setLimit={setLimit}
                    selectableRows={false}
                />
            </div>
          </StyledOrderBlock>
        </form>
        </StyledContainer>
    );
}

export default CountryOrderList;