import React, { useEffect, useState } from "react";
import { StyledContainer, StyledPageTitle, StyledOrderBlock, StyledCard, StyledCardContainer, StylesCardList, ButtonWrapper } from "./style";
import { useNavigate, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getOrdersList, getFolderCount, deleteOrdersData } from "../../../features/order/orderSlice";
import ReactDataTable from "../../../components/ReactDataTable";
import { toast } from "react-toastify";
import MyModal from "../../../components/Modal";
import { encryptVal } from "../../../utils/utility";

const FutureOrders = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const futureOrdersList = useSelector((state) => state?.order?.ordersList);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const [show, setShow] = useState(false);
    const [selectedData, setSelectedData] = useState([]);
    const handleClose = () => setShow(false);

    const handleShow = (orderId) => {
        setShow(true);
        setSelectedData(orderId);
    };


    let futureMonthDataArray = [];
    const [dataTableData, setDataTableData] = useState({
        renderDataTable: false,
        data: [],
        month: "",
    });

    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const [futureOrderData, setFutureOrderData] = useState([]);

    const deleteOrder = (orderID) => {
        let data = {
            orderId: orderID,
            permanentDeletedFlag: false,
            updatingStatus: "Deleted",
        };
        dispatch(deleteOrdersData(data)).then((res) => {
            handleClose();
            if (res.payload) {
                toast.success(`Order has been deleted successfully`, {
                    className: "toast-message",
                });
                dispatch(getFolderCount());
            } else {
                toast.error(`${res.message}`, {
                    className: "toast-message",
                });
            }
        }).catch((err) => {
            if (err.status === 0) {
                toast.success(`${err.message}`, {
                    className: "toast-message",
                });
            }
        });
    };

    const columns = [
        {
            name: "Order ID",
            selector: (row) => row.id,
            sortable: true,
        },
        {
            name: "Name",
            selector: (row) => row.name,
            sortable: true,
        },
        {
            name: "Email",
            selector: (row) => row.email,
            sortable: true,
        },
        {
            name: "Telephone",
            selector: (row) => row.telephone,
            sortable: true,
        },
        {
            name: "Date & Time",
            selector: (row) => row.date,
            sortable: true,
        },
        {
            name: "Processing Date ",
            selector: (row) => row.processDate,
            sortable: true,
        },
        {
            name: "Process Month",
            selector: (row) => row.processMonth,
            sortable: true,
        },

        {
            name: "Status",
            selector: (row) => row.status,
            sortable: true,
        },

        {
            name: "Action",
            selector: (row) =>
                futureOrdersList?.length ? (
                    <span>
                        <Link
                            to={`/admin/view-order/${encryptVal(row.id)}`}
                            style={{
                                border: "1px solid #ce1127",
                                borderRadius: "5px",
                                padding: " 0 5px",
                                minWidth: "80px",
                                display: "inline-block",
                                textAlign: "center",
                                height: "40px",
                                lineHeight: "40px",
                                color: "#111",
                                margin: "5px",
                                textDecoration: "none",
                            }}
                        >
                            View
                        </Link>{" "}
                        <Link
                            to="#"
                            onClick={() => handleShow(row.id)}
                            style={{
                                textDecoration: "none",
                                border: "1px solid #e92a11",
                                borderRadius: "5px",
                                padding: " 0 5px",
                                minWidth: "80px",
                                display: "inline-block",
                                textAlign: "center",
                                height: "40px",
                                lineHeight: "40px",
                                color: " #e92a11",
                                margin: "5px",
                            }}
                        >
                            Delete
                        </Link>
                    </span>
                ) : (
                    ""
                ),
        },
    ];

    useEffect(() => {
        dispatch(getOrdersList({
            orderName: "futureOrder",
            page: page,
            perPage: limit,
        })).unwrap().then((res) => {
            if (res.status === 401) {
                toast.error(`${res?.message}`, { className: "toast-message" });
                localStorage.removeItem("user");
                navigate("/");
            }
        });
        document.title = "Ind-ECD";
    }, [dispatch, navigate, page, limit]);

    useEffect(() => {
        const tableData = futureOrdersList || [];
        console.log('tableData: ', tableData)
        for (let data of tableData) {
            if (typeof futureMonthDataArray[data.process_month] !== "undefined") {
                futureMonthDataArray[data.process_month] = {
                    count: futureMonthDataArray[data.process_month]?.count + 1,
                    monthData: [...futureMonthDataArray[data.process_month]?.monthData, data],
                };
            } else {
                futureMonthDataArray[data.process_month] = { count: 1, monthData: [data] };
            }
        }
        setFutureOrderData(futureMonthDataArray);
        setDataTableData((prev) => ({
            ...prev,
            data: futureMonthDataArray[dataTableData.month]?.monthData || [],
        }));

    }, [futureOrdersList, dataTableData.month]);

    const handleClick = () => {
        window.location.reload();
    };
    return (
        <>
            <StyledContainer>
                <StyledPageTitle>
                    <h1>Future Order</h1>
                    <button type="button" className="btn btn-success" onClick={handleClick}>Refresh</button>
                </StyledPageTitle>
                {typeof dataTableData.renderDataTable !== "undefined" && dataTableData.renderDataTable && (
                    <ButtonWrapper>
                        <button
                            className="btn btn-success"
                            onClick={() => {
                                setDataTableData({
                                    renderDataTable: false,
                                    data: [],
                                    month: "",
                                });
                            }}
                        >
                            Back
                        </button>
                    </ButtonWrapper>
                )}
                {typeof dataTableData.renderDataTable != "undefined" && !dataTableData.renderDataTable ? (
                    <StylesCardList>
                        {months.map((month, i) => (
                            <StyledCard key={i}>
                                {typeof futureOrderData[month] !== "undefined" ? (
                                    <StyledCardContainer>
                                        <Link to="#" onClick={() => {
                                            setDataTableData({
                                                renderDataTable: true,
                                                data: futureOrderData[month]?.monthData || [],
                                                month: month,
                                            });
                                        }}>
                                            <h4>
                                                <span>
                                                    {month}{" "}
                                                    {typeof futureOrderData[month] === "undefined"
                                                        ? 0
                                                        : `(${futureOrderData?.[month].count})`}
                                                </span>
                                            </h4>
                                        </Link>
                                    </StyledCardContainer>
                                ) : (
                                    <StyledCardContainer>
                                        <Link to="#" onClick={() => {
                                            setDataTableData({
                                                renderDataTable: true,
                                                data: [],
                                                month: month,
                                            });
                                        }}>
                                            <h4>
                                                <span>
                                                    {month}{" "}
                                                    {typeof futureOrderData[month] === "undefined"
                                                        ? 0
                                                        : `(${futureOrderData?.[month].count})`}
                                                </span>
                                            </h4>
                                        </Link>
                                    </StyledCardContainer>
                                )}
                            </StyledCard>
                        ))}
                    </StylesCardList>
                ) : (
                    <StyledOrderBlock>
                        <div className="table-block table-responsive">
                            <ReactDataTable
                                data={dataTableData.data}
                                setPage={setPage}
                                setLimit={setLimit}
                                customColumns={columns}
                                orderName="futureOrder"
                            />
                        </div>
                        <MyModal
                            show={show}
                            close={handleClose}
                            size={"s"}
                            confirmAlert={true}
                            noEvent={() => handleClose()}
                            yesEvent={() => deleteOrder(selectedData)}
                        ></MyModal>
                    </StyledOrderBlock>
                )}
            </StyledContainer>
        </>
    );
};

export default FutureOrders;
