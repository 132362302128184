import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { useDispatch } from 'react-redux';
import {StyledLogin, LoginContainer, FormContainer, LoginLogo} from './style.js';
import { useFormik } from 'formik';
import * as yup from 'yup';
import logo  from '../../assets/images/logo.png';
import rightArrow from '../../assets/images/right-arrow.png';
import { authenticate } from '../../features/user/authSlice';
import { toast } from "react-toastify";

const Login = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const initialValues = {
        email: "",
        password: ""
    };

    useEffect(()=> {
        document.title = "Ind-ECD";
    }, []);
    
    const validationSchema = yup.object({
        email: yup.string().email('Invalid email address').required('Email Address is required'),
        password: yup.string().required('Password is required')
    });

    const {errors, values, handleBlur, handleChange, handleSubmit} = useFormik({
        initialValues: initialValues,
        validationSchema: validationSchema,
        onSubmit: (values) => {
            dispatch(authenticate(values)).unwrap().then( (res) => {
                if (res.status === 1) {
                    toast.success(`${res?.message}`, {
                      className: "toast-message",
                    });
                    navigate("/admin");
                    console.log("logged in successfully");
                } else if (res?.message) {
                    toast.error(`${res?.message}`, {
                      className: "toast-message",
                    });
                }
            });
        },
    });
    return (
        <>
            <StyledLogin>
                <LoginContainer>
                    <LoginLogo>
                        <img src={logo} alt='adminLogo' />
                    </LoginLogo>
                    <FormContainer>
                        <form onSubmit={handleSubmit}>
                            <div className='form-group'>
                                <label>Email ID</label>
                                <input
                                    type='email'
                                    name='email'
                                    placeholder='Your Email Address'
                                    className='form-control'
                                    values={values.email}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                />
                                <p>{errors.email}</p>
                            </div>
                            <div className='form-group'>
                                <label>Password</label>
                                <input
                                    type='password'
                                    name='password'
                                    placeholder='Password'
                                    className='form-control'
                                    values={values.password}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    autoComplete = 'off' 
                                />
                                <p>{errors.password}</p>
                            </div>
                            <div className='form-btn'>
                                <button type='submit' className='btn blue-btn'>
                                    Sign In{' '}
                                    <span><img src={rightArrow} alt='rightArrow' /></span>
                                </button><br />
                            </div>
                        </form>
                    </FormContainer>
                </LoginContainer>
            </StyledLogin>
        </>
    )
}

export default Login;